import router from "page";
import { merge } from "lodash-es";
import { params } from "./stores";
import App from "./App.svelte";
import { qs } from "@parkingboss/utils";
import { api } from "./api";
import style from "../styles/app.scss";

//console.log(api, api.isLoggedIn(), api.user);
if (!api.isLoggedIn()) api.logIn(true);
window.API = api;

const app = window.app = new App({ target: document.body, props: {} });

//querystring
router("*", function(ctx, next) {
    ctx.query = qs.parse(ctx.querystring);
    next();
});

function state(ctx, next) {
    params.set(merge({}, ctx.params, ctx.query, qs.parse(location.hash && location.hash.substring(1))));
    if(!!next) next();
}
function unstate(ctx, next) {
    //params.set({});
    next();
}

function end(ctx) {

}

router.exit("*", unstate);


function view(viewname) {
    return function(ctx, next) {
        ctx.params.view = viewname;
        next();
    };
}

router("/properties/:property/*", state);
router("/properties/:property", view("property"), state, end);

// policy catch all
router("/properties/:property/policies/:policy/*", state);
router("/properties/:property/policies/:policy", view("policy"), state, end);

router("/properties/:property/media/:media", view("media"), state, end);
router("/properties/:property/tenants/:tenant", view("tenants"), state, end);
// property subview
router(`/properties/:property/:view`, state, end);

router.start();