<script>

import image from "../../assets/permit-back-right.jpg";
import InteriorPlacement from "./InteriorPlacement.svelte";
export let lang = "en";

</script>
<figure class="placement">

    <img alt="Outside Back Placement" src="{image}" />

    <figcaption>
        <InteriorPlacement lang={lang} />
        <h1>{#if "es" == lang}
            Donde aplicar
            {:else if "fr" == lang}
            Où appliquer 
            {:else}
            Where to apply
            {/if}
             Smart Decals:</h1>
        <ul>
            <li>
                {#if "es" == lang}
                Exterior de la ventana trasera inferior, lado del pasajero
                {:else if "fr" == lang}
                À l'extérieur de la vitre arrière inférieure, côté passager
                {:else}
                Outside of lower rear window, passenger side
                {/if}
            </li>
        </ul>

    </figcaption>

</figure>