<script>
    import { get, filter, upperFirst, capitalize } from "lodash-es";
    export let property = null;
    export let policies = [];
    export let lang = "en";
    
</script>
<ul>
    <li>
        {#if "es" === lang}
        Ver reglas de estacionamiento
        {:else if "fr" === lang}
        Visionement des règles de stationnement
        {:else}
        View parking rules 
        {/if}
    </li>
    {#each filter(policies, "issue.enabled") as item}
    <li><dfn>{upperFirst(item.issue.title)}</dfn> for <data class="{item.type}" value="{item.id}">{capitalize(item.title)}</data></li>
    <!-- {#if get(item, "preauthentication.enabled", false)}
    <li>
        <dfn>{#if "es" === lang}
        Preautorizar
        {:else if "fr" === lang}
        Préautoriser
        {:else}
        Preauthorize 
        {/if}</dfn>
         <data class="{item.type}" value="{item.id}">{capitalize(item.title)}</data></li>
    {/if} -->
    {/each}
    {#if get(property, "tenants.selfservice.enabled")}
    <li>
        {#if "es" === lang}
        Verifique su uso
        {:else if "fr" === lang}
        Vérification de votre utilisation
        {:else}
        Check your usage 
        {/if}
    </li>
    {/if}
    {#if get(property, "violations.selfservice.enabled", false)}
    <li>
        {#if "es" === lang}
        Violaciones de búsqueda
        {:else if "fr" === lang}
        Recherche d’ infraction
        {:else}
        Lookup violations 
        {/if}
    </li>
    {/if}
</ul>