<script>
    import { get } from "lodash-es";
    import Logo from "./Logo.svelte";
    import FairHousing from "./FairHousing.svelte";
    import Media from "./Media.svelte";
    import Thanks from "./Thanks.svelte";
    import Language from "./Language.svelte";
    export let property = null;
    export let item = null;

    import InteriorFrontDriver from "./InteriorFrontDriver.svelte";
    import InteriorFrontPassenger from "./InteriorFrontPassenger.svelte";
    import ExteriorRearDriver from "./ExteriorRearDriver.svelte";
    import ExteriorRearPassenger from "./ExteriorRearPassenger.svelte";

    
    export let placement = "inside-front";
    export let lang = "en";

</script>
<article>
    <nav>
       <Language bind:value={lang} /> 
    </nav>
    <header>
        <Logo property={property} />
    </header>
    <Media item={item} lang={lang} />
    {#if placement === "interior-front-driver"}
        <InteriorFrontDriver lang={lang} />
    {/if}
    {#if placement === "interior-front-passenger"}
        <InteriorFrontPassenger lang={lang} />
    {/if}
    {#if placement === "exterior-rear-driver"}
       <ExteriorRearDriver lang={lang} />
    {/if}
    {#if placement === "exterior-rear-passenger"}
        <ExteriorRearPassenger lang={lang} />
    {/if}
    
    <Thanks property={property} lang={lang} />
    <FairHousing property={property}/>
</article>