<script>

export let lang = "en";

</script>
<h1>{#if "es" == lang}
    Cómo aplicar 
    {:else if "fr" == lang}
    Comment appliquer
    {:else}
    How to apply 
    {/if}
     Smart Decals:
</h1>
<ol>
    <li>
        {#if "es" == lang}
        Aplicar a temperaturas entre 40 y 120 F
        {:else if "fr" == lang}
        Appliquer à des températures comprises entre 40 et 120 F
        {:else}
        Apply in temperatures between 40 and 120 F
        {/if}
        </li>
    <li>
        {#if "es" == lang}
        Limpia y seca la superficie antes de aplicar tu calcomanía
        {:else if "fr" == lang}
        Nettoyez et séchez la surface avant d'appliquer votre décalcomanie
        {:else}
        Clean and dry the surface before applying your decal
        {/if}
        </li>
    <li>
        {#if "es" == lang}
        Aplique su calcomanía solo a la superficie del vidrio
        {:else if "fr" == lang}
        Appliquez votre décalcomanie uniquement sur la surface du verre
        {:else}
        Apply your decal to glass surface only
        {/if}
    </li>
    <li>{#if "es" == lang}
        Despegue con cuidado la calcomanía del revestimiento de papel comenzando en la esquina (el permiso tendrá ranuras a prueba de manipulaciones)
        {:else if "fr" == lang}
        Décollez soigneusement l'autocollant de la doublure en papier en commençant par le coin (le permis aura des fentes inviolables)
        {:else}
        Carefully peel the decal from paper lining by starting at the corner (permit will have tamper-resistant slits in it)
        {/if}
        </li>
    <li>
        {#if "es" == lang}
        Tenga cuidado de no tocar el adhesivo
        {:else if "fr" == lang}
        Veillez à ne pas toucher la partie de l'adhésif
        {:else}
        Be careful not to touch the adhesive
        {/if}</li>
    <li>
        {#if "es" == lang}
        Comenzando en el centro, aplique una presión uniforme en toda la calcomanía para asegurar una unión adecuada
        {:else if "fr" == lang}
        En commençant par le centre, appliquez une pression uniforme sur tout l'autocollant pour assurer une bonne adhérence
        {:else}
        Starting in the center, apply even pressure across entire decal to ensure proper bonding
        {/if}
    </li>
</ol>