
<script>
    import { upperFirst } from "lodash-es";
    export let tenant = null;
    export let lang = "en";
</script>

{#if tenant && tenant.authcode != null}
<dl>
    <dt>{upperFirst(tenant.format)}:</dt><dd>{#if tenant.display}{tenant.display}{:else}<input required type="text" value="" />{/if}</dd>
    <dt>Passcode:</dt><dd>{#if tenant.authcode}{tenant.authcode}{:else}<input required type="number" value="" size="8" />{/if}</dd>
</dl>
{/if}