<script>
  import {
    propertyId,
    policyId,
    view,
    property,
    properties,
    policies,
    policy,
    units,
    tenants,
    media,
    params,
    permits,
  } from "./stores";
  import { get, find, filter } from "lodash-es";
  import Handout from "./components/Handout.svelte";
  import SmartDecal from "./components/SmartDecal.svelte";
  import router from "page";

  let pdfUrl = null;
  let generating = null;
  let showmedia = true;
  let lang = "en";

  $: loaded = $property && $policies && $tenants;
  $: name = get($property, "name");

  $: smartdecals =
    get($property, "media.enabled") &&
    get($property, "media.format") == "smart-decal";

  $: if (loaded && $params.pdf === "true") pdf();

  $: mediaItem = $params.media && find($media, { id: $params.media });
  $: mediaPermits =
    mediaItem &&
    filter($permits, (item) => get(item, "media.id") === $params.media);
  //$: console.log("permit=", permit);

  // {#each $tenants as item}
  //         {#if item && item.authcode && (!$params.tenant || item.id === $params.tenant)}
  //             <Handout property={$property} tenant={item} policies={$policies} />
  //         {/if}
  //     {/each}

  $: document.documentElement[$view ? "setAttribute" : "removeAttribute"](
    "data-view",
    $view
  );
  $: document.documentElement[$propertyId ? "setAttribute" : "removeAttribute"](
    "data-property",
    $propertyId
  );
  $: document.documentElement[$policyId ? "setAttribute" : "removeAttribute"](
    "data-policy",
    $policyId
  );
  //$: document.documentElement[$permitId ? "setAttribute" : "removeAttribute"]("data-permit", $permitId);

  function pdf(e) {
    if (!!e) e.preventDefault();

    generating = true;

    fetch("https://v2.api2pdf.com/chrome/pdf/html", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "c550eb1b-888b-4377-ac99-096487c3ee13",
      },
      body: JSON.stringify({
        html: new XMLSerializer()
          .serializeToString(document)
          .replaceAll(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, "")
          .replaceAll(' href="/', ' href="' + window.location.origin + "/")
          .replaceAll(' src="/', ' src="' + window.location.origin + "/"),
        inline: false,
        inlinePdf: false, // should download on nav
        fileName: `${name} Parking Notices.pdf`,
        options: {
          //"landscape":"true",
          width: "8.5in",
          height: "11in",
          marginTop: "0.75in",
          marginBottom: "0.75in",
          marginLeft: "0.75in",
          marginRight: "0.75in",
          printBackground: true,
        },
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        generating = false;
        window.location.href = json.FileUrl;
      });
  }
</script>

<nav>
  <ul>
    <li />
    <li class:selected={"property" === $view}>
      <a href="/properties/{$propertyId}">General</a>
    </li>
    {#if get($property, "tenants.enabled", false)}
      <li class:selected={"tenants" === $view}>
        <a href="/properties/{$propertyId}/tenants"
          >{$property.tenants.title}s</a
        >
        {#if "tenants" === $view && $tenants}
          <select
            value={$params.tenant}
            on:change={(e) =>
              router(`/properties/${$propertyId}/tenants/${e.target.value}`)}
            on:blur={(e) =>
              router(`/properties/${$propertyId}/tenants/${e.target.value}`)}
          >
            <option value=""
              >All {get($property, "tenants.format", "unit")}s</option
            >
            <option value="new">Fill-in</option>
            {#each $tenants as item}
              {#if item && item.authcode}
                <option value={item.id}>{item.display}</option>
              {/if}
            {/each}
          </select>
        {/if}
      </li>
    {/if}
    {#if smartdecals}
      <li class:selected={"media" === $view}>
        <a href="/properties/{$propertyId}/media">Smart Decals</a>
        {#if "media" === $view && $media}
          <select
            value={$params.media}
            on:change={(e) =>
              router(`/properties/${$propertyId}/media/${e.target.value}`)}
            on:blur={(e) =>
              router(`/properties/${$propertyId}/media/${e.target.value}`)}
          >
            <option value=""
              >All {get($property, "media.title", "Smart Decal")}s</option
            >
            {#each $media as item}
              {#if item}
                <option value={item.id}>{item.display}</option>
              {/if}
            {/each}
          </select>
        {/if}
      </li>
    {/if}
    <!-- <li class:selected={"policies" === $view}><a href="./properties/{$propertyId}/policies">Notices</a></li>
        <li class:selected={"tenants" === $view}><a href="./properties/{$propertyId}/tenants">{get($property, "tenants.title", "Unit")}s</a></li> -->
  </ul>
  {#if loaded}
    <button on:click={pdf} disabled={generating}
      >{generating ? "Generating…" : "Download PDF"}</button
    >
  {/if}
</nav>
<!-- <form>
    <ul on:change={e => (pdfUrl = null) || (!!e.target.checked && (docs = e.target.value))}>
        <li><input id="docs-general" type="radio" name="docs" checked value="general" disabled={generating} /><label for="docs-general">General</label></li>
        <li><input id="docs-tenants" type="radio" name="docs" value="tenants" disabled={generating} /><label for="docs-tenants">{get($property, "tenants.title", "Unit")} Handouts</label></li>
        <li><input id="docs-amenity" type="radio" name="docs" value="policies" disabled={generating} /><label for="docs-amenity">Amenity Notices</label></li>
    </ul>
    {#if loaded}
    <button on:click={pdf} disabled={generating}>{generating ? "Generating…" : "Download PDF"}</button>
    {/if}
</form> -->
{#if $property}
  {#if $view === "tenants"}
    {#if $params.tenant == "new"}
      <Handout
        property={$property}
        tenant={{
          format: get($property, "tenants.format", "unit"),
          display: "",
          authcode: "",
        }}
        policies={$policies}
        bind:lang
        bind:showmedia
      />
    {/if}
    {#each $tenants as item}
      {#if item && item.authcode && (!$params.tenant || item.id === $params.tenant)}
        <Handout
          property={$property}
          tenant={item}
          policies={$policies}
          bind:lang
          bind:showmedia
        />
      {/if}
    {/each}
    <!-- {:else if $view === "policies" && $policies}
        <PostNotice property={$property} />
        {#each $policies as policy}
            {#if policy}
            <PostNotice property={$property} policy={policy} />
            {/if}
        {/each} -->
  {:else if "media" === $view}
    {#if mediaPermits && mediaPermits.length && $property.media.placement}
      {#each mediaPermits as permit}
        <!-- {#if get($property, "media.placement")} -->
        <SmartDecal
          property={$property}
          item={permit}
          placement={$property.media.placement}
        />
        <!-- {:else}
                    <SmartDecal property={$property} item={permit} placement="interior-front-driver"/>
                    <SmartDecal property={$property} item={permit} placement="interior-front-passenger"/>
                    <SmartDecal property={$property} item={permit} placement="exterior-rear-driver"/>
                    <SmartDecal property={$property} item={permit} placement="exterior-rear-passenger"/>
                {/if} -->
      {/each}
    {:else if get($property, "media.placement")}
      <SmartDecal
        property={$property}
        item={mediaItem}
        placement={$property.media.placement}
      />
    {:else}
      <SmartDecal
        property={$property}
        item={mediaItem}
        placement="interior-front-driver"
      />
      <SmartDecal
        property={$property}
        item={mediaItem}
        placement="interior-front-passenger"
      />
      <SmartDecal
        property={$property}
        item={mediaItem}
        placement="exterior-rear-driver"
      />
      <SmartDecal
        property={$property}
        item={mediaItem}
        placement="exterior-rear-passenger"
      />
    {/if}
  {:else}
    <Handout property={$property} policies={$policies} />
  {/if}
{/if}
