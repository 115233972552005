<script>
  import QR from "./QR.svelte";
  import FairHousing from "./FairHousing.svelte";
  import Thanks from "./Thanks.svelte";
  import Intro from "./Intro.svelte";
  import TenantAccess from "./TenantAccess.svelte";
  import ParkingOptions from "./ParkingOptions.svelte";
  import Language from "./Language.svelte";
  import InteriorFrontDriver from "./InteriorFrontDriver.svelte";
  import InteriorFrontPassenger from "./InteriorFrontPassenger.svelte";
  import ExteriorRearDriver from "./ExteriorRearDriver.svelte";
  import ExteriorRearPassenger from "./ExteriorRearPassenger.svelte";
  import { get } from "lodash-es";
  import PlacementSetting from "./PlacementSetting.svelte";
  import SmartDecalSetting from "./SmartDecalSetting.svelte";

  export let property = null;
  export let policy = null;
  export let tenant = null;
  export let policies = null;
  export let lang;
  export let showplacement = true;
  export let showmedia;

  $: placement = get(property, "media.placement");
  $: smartdecals =
    get(property, "media.enabled") &&
    get(property, "media.format") == "smart-decal";
</script>

<article class="page handout">
  <nav>
    <!-- {#if smartdecals}
        <PlacementSetting bind:value={showplacement} />
        {/if} -->
    {#if smartdecals}
      <SmartDecalSetting bind:value={showmedia} />
    {/if}
    <Language bind:value={lang} />
  </nav>
  <Intro {property} {lang} />

  <QR {property} {policy} {lang} />
  <ParkingOptions
    {property}
    policies={policies?.filter((p) => showmedia || !p.media?.request)}
    {lang}
  />
  <TenantAccess {tenant} {lang} />
  {#if showmedia && showplacement}
    {#if placement === "interior-front-driver"}
      <InteriorFrontDriver {lang} />
    {/if}
    {#if placement === "interior-front-passenger"}
      <InteriorFrontPassenger {lang} />
    {/if}
    {#if placement === "exterior-rear-driver"}
      <ExteriorRearDriver {lang} />
    {/if}
    {#if placement === "exterior-rear-passenger"}
      <ExteriorRearPassenger {lang} />
    {/if}
  {/if}
  <Thanks {property} {lang} />
  <FairHousing {property} />
</article>
