<script>

import image from "../../assets/permit-front-left.jpg";
import InteriorPlacement from "./InteriorPlacement.svelte";
export let lang = "en";

</script>
<figure class="placement">

    <img alt="Inside Front Placement" src="{image}" />

    <figcaption>

        <InteriorPlacement lang={lang} />
        <h1>{#if "es" == lang}
            Donde aplicar
            {:else if "fr" == lang}
            Où appliquer 
            {:else}
            Where to apply
            {/if}
             Smart Decals:</h1>
        <ul>
            <li>
                {#if "es" == lang}
                Dentro inferior del parabrisas, del lado del conductor
                {:else if "fr" == lang}
                A l’intérieur du pare-brise inférieur, côté conducteur
                {:else}
                Inside lower windshield, driver side
                {/if}
            </li>
            <li>
                {#if "es" == lang}
                Asegúrese de que el código de barras esté hacia afuera y sea claramente visible
                {:else if "fr" == lang}
                Assurez-vous que le code-barres est orienté vers l'extérieur et clairement visible
                {:else}
                Be sure the barcode is facing out and clearly visible
                {/if}
            </li>
            <li>
                {#if "es" == lang}
                Asegúrese de que la calcomanía no esté colocada en el área tintada de la ventana
                {:else if "fr" == lang}
                Assurez-vous que l'autocollant n'est pas placé dans la zone teintée de la fenêtre
                {:else}
                Be sure the decal is not placed in tinted area of window
                {/if}
            </li>
        </ul>

    </figcaption>

</figure>