<script>
    import Logo from "./Logo.svelte";
    export let property = null;
    export let lang = "en";

</script>

<header>
    <Logo property={property}/>
    <h1>
        {#if "es" == lang}
        Bienvenido a Parking Attendant para 
        {:else if "fr" == lang}
        Bienvenue à Parking Attendant pour
        {:else}
        Welcome to Parking Attendant for
        {/if}
        {property.name}</h1>
</header>
<p>
    {#if "es" == lang}
    El estacionamiento es un servicio valioso aquí y nuestro nuevo Parking Attendant ayudará a que todos sean responsables para que el estacionamiento sea libre de estrés, más disponible y justo. {#if property.parking?.alert}<em>{property.parking.alert}</em>{/if} El incumplimiento de nuestras reglas de estacionamiento puede resultar en remolque, inmovilización o tarifas. Con Parking Attendant puede:
    {:else if "fr" == lang}
    Le stationnement ici est une aménité de valeur et notre nouveau service Parking Attendant aidera tout le monde à être responsable avec le stationnement sans stresser, plus disponible et équitable. {#if property.parking?.alert}<em>{property.parking.alert}</em>{/if} Le non-respect de nos règles de stationnement peut entraîner à un remorquage, une immobilisation ou des frais. Avec Parking Attendant, vous pouvez:
    {:else}
    Parking is a valuable amenity here and our new Parking Attendant will help keep everyone accountable so parking is stress-free, more available, and fair. {#if property.parking?.alert}<em>{property.parking?.alert}</em>{/if} Failure to follow our parking rules may result in tow, immobilization, or fees. With Parking Attendant you can:
    {/if}
</p>