<script>

    export let value = "en";

</script>

<select value={value} on:change={e => (value = e.target.value)} on:blur={e => (value = e.target.value)}>
    <option value="en">English</option>
    <option value="es">Spanish</option>
    <option value="fr">French</option>
</select>